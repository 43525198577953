// Generated by Framer (6124c77)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import GradientBlurAnimation from "./ccmMiGB5j";
import * as localizedValues from "./hMwivPYeU-0.js";
const GradientBlurAnimationFonts = getFonts(GradientBlurAnimation);

const cycleOrder = ["pOq9WQ3hm", "IHjKJhXoZ", "LKuuE3tn3"];

const serializationHash = "framer-dsge0"

const variantClassNames = {IHjKJhXoZ: "framer-v-154t7xm", LKuuE3tn3: "framer-v-pz7ksh", pOq9WQ3hm: "framer-v-p4cha"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {IGeMnN5IH: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Tablet ": "LKuuE3tn3", Main: "pOq9WQ3hm", Mobile: "IHjKJhXoZ"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "pOq9WQ3hm"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "pOq9WQ3hm", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-p4cha", className, classNames)} data-framer-name={"Main"} layoutDependency={layoutDependency} layoutId={"pOq9WQ3hm"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-5f9c34e1-c1a8-415b-a74b-c92716a82bf1, rgb(3, 169, 244))", ...style}} {...addPropertyOverrides({IHjKJhXoZ: {"data-framer-name": "Mobile"}, LKuuE3tn3: {"data-framer-name": "Tablet "}}, baseVariant, gestureVariant)}><motion.div className={"framer-jeh0k8"} data-framer-name={"BG"} layoutDependency={layoutDependency} layoutId={"RYItslJH3"} style={{background: "linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)"}}><ComponentViewportProvider width={`calc(${componentViewport?.width || "100vw"} - 240px)`} {...addPropertyOverrides({IHjKJhXoZ: {width: `calc(${componentViewport?.width || "100vw"} - 32px)`}, LKuuE3tn3: {width: `calc(${componentViewport?.width || "100vw"} - 96px)`}}, baseVariant, gestureVariant)}><motion.div className={"framer-zdk3an-container"} layoutDependency={layoutDependency} layoutId={"gi96PQhF3-container"}><GradientBlurAnimation height={"100%"} id={"gi96PQhF3"} layoutId={"gi96PQhF3"} style={{height: "100%", width: "100%"}} uZ_AFjUWG={70} width={"100%"} ZmZph0lWn={15} {...addPropertyOverrides({IHjKJhXoZ: {ZmZph0lWn: 8}, LKuuE3tn3: {ZmZph0lWn: 10}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-dsge0.framer-f7i30t, .framer-dsge0 .framer-f7i30t { display: block; }", ".framer-dsge0.framer-p4cha { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 714px; justify-content: center; overflow: visible; padding: 180px 120px 144px 120px; position: relative; width: 1200px; }", ".framer-dsge0 .framer-jeh0k8 { flex: none; height: 100%; left: calc(50.00000000000002% - 100% / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 100% / 2); width: 100%; z-index: 0; }", ".framer-dsge0 .framer-zdk3an-container { flex: none; height: 100%; left: 0px; position: absolute; top: 0px; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-dsge0.framer-p4cha { gap: 0px; } .framer-dsge0.framer-p4cha > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-dsge0.framer-p4cha > :first-child { margin-top: 0px; } .framer-dsge0.framer-p4cha > :last-child { margin-bottom: 0px; } }", ".framer-dsge0.framer-v-154t7xm.framer-p4cha { gap: 48px; height: min-content; min-height: 654px; padding: 120px 16px 24px 16px; width: 390px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-dsge0.framer-v-154t7xm.framer-p4cha { gap: 0px; } .framer-dsge0.framer-v-154t7xm.framer-p4cha > * { margin: 0px; margin-bottom: calc(48px / 2); margin-top: calc(48px / 2); } .framer-dsge0.framer-v-154t7xm.framer-p4cha > :first-child { margin-top: 0px; } .framer-dsge0.framer-v-154t7xm.framer-p4cha > :last-child { margin-bottom: 0px; } }", ".framer-dsge0.framer-v-pz7ksh.framer-p4cha { padding: 180px 48px 144px 48px; width: 910px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 714
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"IHjKJhXoZ":{"layout":["fixed","auto"]},"LKuuE3tn3":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerhMwivPYeU: React.ComponentType<Props> = withCSS(Component, css, "framer-dsge0") as typeof Component;
export default FramerhMwivPYeU;

FramerhMwivPYeU.displayName = "Headline Home Copy";

FramerhMwivPYeU.defaultProps = {height: 714, width: 1200};

addPropertyControls(FramerhMwivPYeU, {variant: {options: ["pOq9WQ3hm", "IHjKJhXoZ", "LKuuE3tn3"], optionTitles: ["Main", "Mobile", "Tablet "], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerhMwivPYeU, [{explicitInter: true, fonts: []}, ...GradientBlurAnimationFonts], {supportsExplicitInterCodegen: true})